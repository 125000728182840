import React from "react";

import { Container, Header, Logo, Footer } from "./styles";

const logo = require("../../assets/images/logo_retangular.png");

const UniqueOverlay = (props) => {
  let opacity = 0;
  if (props.scrollYProgress >= 0.9) {
    opacity = (props.scrollYProgress - 0.9) * 10;
  }

  return (
    <Container>
      <Header>
        <Logo src={logo} />
      </Header>

      <Footer style={{ opacity }}>
        <ul>
          <li>
            <a href="#this">Rhodium Tecnologia 2020</a>
          </li>
        </ul>
      </Footer>
    </Container>
  );
};

export default UniqueOverlay;

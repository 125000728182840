import React, { Component } from "react";
import UniqueOverlay from "../UniqueOverlay";
import Contact from "../Contact";

import {
  Container,
  ModelsWrapper,
  DefaultOverlayContent,
  Heading,
  Buttons,
  OverlaysRoot,
  Header,
  CustomerLogo,
} from "./styles";

const sections = [
  {
    name: "Coca-Cola na sua casa",
    description: "MVP, Protótipo e produção da geladeira para venda autônoma em condomínios",
    bgClass: "coca_cola_na_sua_casa",
    logo: require("../../assets/images/icoke-logo.png"),
    type: "project",
    contentDiv: React.createRef(),
    containerDiv: React.createRef(),
  },
  {
    name: "Retornável",
    description: "Solução para vasilhames digitais",
    bgClass: "retornavel",
    logo: require("../../assets/images/retornavel-logo.png"),
    type: "project",
    contentDiv: React.createRef(),
    containerDiv: React.createRef(),
  },

  // {
  //   name: "Locker",
  //   description: "Lorem ipsum",
  //   type: "project",
  //   contentDiv: React.createRef(),
  //   containerDiv: React.createRef(),
  // },
  // {
  //   name: "Loja Conceito Coca-Cola",
  //   description: "Lorem ipsum",
  //   type: "project",
  //   contentDiv: React.createRef(),
  //   containerDiv: React.createRef(),
  // },

  {
    name: "Brasal Incorporações",
    description: "Desenvolvimento do aplicativo para relacionamento com o cliente",
    logo: require("../../assets/images/app-brasal-inc-logo.png"),
    bgClass: "app_brasal_inc",
    type: "project",
    contentDiv: React.createRef(),
    containerDiv: React.createRef(),
  },
  {
    name: "Funcesp",
    description: "Desenvolvimento de sistema simulador para contratação de Previdência",
    logo: require("../../assets/images/funcesp-logo.png"),
    bgClass: "funcesp",
    type: "project",
    contentDiv: React.createRef(),
    containerDiv: React.createRef(),
  },
  {
    type: "contact",
    bgClass: "contact",
    contentDiv: React.createRef(),
    containerDiv: React.createRef(),
  },
];

const vh = window.innerHeight;

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollY: 0,
      scrollYProgress: 0,
      subject: "",
    };
    this.wrapperRef = React.createRef();
    this.projectContent = [];
    this.projectContent1 = React.createRef();
    this.updateScrollValue = this.updateScrollValue.bind(this);
    this.renderSection = this.renderSection.bind(this);
    this.renderSectionBg = this.renderSectionBg.bind(this);
    this.sectionScrollProgress = [];
  }

  componentDidMount() {
    this.updateScrollValue();
    if (this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener("scroll", this.updateScrollValue);
    }
    window.addEventListener("resize", this.updateScrollValue);
  }

  componentWillUnmount() {
    this.wrapperRef.current.removeEventListener("scroll", this.updateScrollValue);
    window.removeEventListener("resize", this.updateScrollValue);
  }

  updateScrollValue() {
    if (this.wrapperRef.current) {
      const { scrollTop, scrollHeight, offsetHeight } = this.wrapperRef.current;

      const fullScroll = scrollHeight - offsetHeight;

      const scrollYProgress = scrollTop / fullScroll;
      this.setState({
        scrollY: scrollTop,
        scrollYProgress,
        width: window.innerWidth,
        height: window.innerHeight,
      });
      sections.map((project, index) => {
        const containerDivProject = project.containerDiv;

        const sectionScrollProgress =
          (scrollTop - containerDivProject.current.offsetTop) /
          containerDivProject.current.offsetHeight;

        this.sectionScrollProgress[index] = sectionScrollProgress;
        return true;
      });
    }
  }

  sendToInterest(projectIndex) {
    // console.log(projectIndex);
    const project = sections[projectIndex];

    // console.log(project);
    const subject = `Interessado no projeto ${project.name}`;
    // console.log(subject);
    // console.log(window);
    const contact = sections[sections.length - 1];
    // console.log(contact.bgClass);
    // window.scrollTo({ top: vh, behavior: "smooth" });
    // window.scrollTo(0, contact.containerDiv);
    contact.containerDiv.current.scrollIntoView({ behavior: "smooth", block: "start" });
    this.updateScrollValue();
    this.setState({
      subject,
    });
  }

  renderProject(project, index) {
    return (
      <>
        <Header>
          <CustomerLogo src={project.logo} />
        </Header>
        <Heading className={project.bgClass}>
          <h1>{project.name}</h1>
          <h2>
            {project.description}
            {/* {window.innerHeight} */}
          </h2>
        </Heading>
        {/* <button onClick={() => this.sendToInterest(index)}>
          Tenho interesse em algo semelhante
        </button> */}

        <Buttons>
          <button onClick={() => this.sendToInterest(index)}>
            Tenho interesse em algo semelhante
          </button>
          <button className="white">Mais informações</button>
        </Buttons>
      </>
    );
  }

  renderSection(section, index) {
    // console.log("renderSection");
    const sectionScrollProgress = this.sectionScrollProgress[index];

    let opacity = 0;
    if (sectionScrollProgress >= -0.45 && sectionScrollProgress <= -0.05) {
      opacity = 1 - ((sectionScrollProgress * -1 - 0.05) / 40) * 100;
    }
    if (sectionScrollProgress > 0.05 && sectionScrollProgress < 0.45) {
      opacity = 1 - ((sectionScrollProgress - 0.05) / 40) * 100;
    }
    if (sectionScrollProgress >= -0.05 && sectionScrollProgress <= 0.05) {
      opacity = 1;
    }

    const pointerEvents = opacity === 0 ? "none" : "auto";

    if (section.type === "project") {
      return (
        <DefaultOverlayContent
          style={{ opacity, pointerEvents }}
          ref={section.contentDiv}
          key={index}
          vh={`${vh}px`}
        >
          {this.renderProject(section, index)}
        </DefaultOverlayContent>
      );
    }

    if (section.type === "contact") {
      // console.log(this.state.subject);
      return (
        <DefaultOverlayContent
          style={{ opacity, pointerEvents }}
          ref={section.contentDiv}
          key={index}
          vh={`${vh}px`}
          id="contact"
        >
          <Contact subject={this.state.subject} key="contact" />
        </DefaultOverlayContent>
      );
    }
  }

  renderSectionBg(section, index) {
    return (
      <Container
        vh={`${vh}px`}
        className={section.bgClass}
        ref={section.containerDiv}
        key={index}
      />
    );
  }

  render() {
    // this.projectContent1 = React.createRef();
    return (
      <ModelsWrapper vh={`${vh}px`} ref={this.wrapperRef}>
        <OverlaysRoot>{sections.map(this.renderSection)}</OverlaysRoot>
        {sections.map(this.renderSectionBg)}
        <UniqueOverlay scrollYProgress={this.state.scrollYProgress} />
      </ModelsWrapper>
    );
  }
}

export default index;

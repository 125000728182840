import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  padding: 100px 20px 70px 20px;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 600px) {
    padding: 200px 20px 70px 20px;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Info = styled.div`
  /* height: 100vh; */
  h1 {
    color: #3cb877;
    font-size: 28px;
    margin-bottom: 20px;
    @media (min-width: 600px) {
      font-size: 30px;
    }
  }

  p {
    margin-bottom: 20px;
    @media (min-width: 600px) {
      margin-bottom: 0px;
    }
  }
`;

export const Form = styled.div`
  h2 {
    font-size: 18px;
    margin-bottom: 20px;
    @media (min-width: 600px) {
      font-size: 20px;
    }
  }

  input,
  textarea {
    width: 100%;
    border: #3cb877 solid 1px;
    margin-bottom: 10px;
    background: #283b32;
    padding: 10px;
    color: #fff;
    border-radius: 2px;

    ::-webkit-input-placeholder {
      color: #ababab;
    }

    :-ms-input-placeholder {
      color: #ababab;
    }

    ::placeholder {
      color: #ababab;
    }
  }

  button {
    width: 100%;
    background: #fff;
    color: #1a1720;
    opacity: 0.85;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-transform: uppercase;

    padding: 13px 40px;
    border-radius: 2px;
    border: none;
    outline: 0;
    cursor: pointer;
  }

  @media (min-width: 600px) {
    max-width: 40%;
  }
`;

import styled from "styled-components";
import { motion } from "framer-motion";

// import coca_cola_na_sua_casa from "../../assets/images/coca-cola-na-sua-casa.png";

import coca_cola_na_sua_casa_mobile from "../../assets/images/icoke-mobile.jpg";
import coca_cola_na_sua_casa_desktop from "../../assets/images/icoke-desktop.jpg";

import retornavel_mobile from "../../assets/images/retornavel-mobile.jpg";
import retornavel_desktop from "../../assets/images/retornavel-desktop.jpg";

import app_brasal_inc_mobile from "../../assets/images/app-brasal-inc-mobile.jpg";
import app_brasal_inc_desktop from "../../assets/images/app-brasal-inc-desktop.jpg";

import funcesp_mobile from "../../assets/images/funcesp-mobile.jpg";
import funcesp_desktop from "../../assets/images/funcesp-desktop.jpg";

import contact_mobile from "../../assets/images/contact-mobile.jpg";
import contact_desktop from "../../assets/images/contact-desktop.jpg";

// const vh = window.innerHeight;

export const ModelsWrapper = styled.div`
  height: ${(props) => props.vh};
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100); */

  scroll-snap-type: y mandatory;
  overflow-y: scroll;

  background: #252525;
`;

export const Container = styled.div`
  height: ${(props) => props.vh};
  /* height: 100vh; */
  /* height: calc(var(--vh, 1vh) * 100); */

  scroll-snap-align: start;

  /* background: #000; */
  /* background: url("https://www.qab.com.br/uploads/img/slide/6/thumb-2000-700/54a9bd4e09ff49b46d8b31dd04271b65.png")
    no-repeat center center; */
  background-position: center top;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &.coca_cola_na_sua_casa {
    background-image: url(${coca_cola_na_sua_casa_mobile});
    /* background-position: center top; */
    @media (min-width: 600px) {
      background-image: url(${coca_cola_na_sua_casa_desktop});
    }
  }

  &.app_brasal_inc {
    background-image: url(${app_brasal_inc_mobile});
    @media (min-width: 600px) {
      background-image: url(${app_brasal_inc_desktop});
    }
  }

  &.retornavel {
    background-image: url(${retornavel_mobile});
    @media (min-width: 600px) {
      background-image: url(${retornavel_desktop});
    }
  }

  &.funcesp {
    background-image: url(${funcesp_mobile});
    @media (min-width: 600px) {
      background-image: url(${funcesp_desktop});
    }
  }

  &.contact {
    background-image: url(${contact_mobile});
    @media (min-width: 600px) {
      background-image: url(${contact_desktop});
    }
  }
`;

export const OverlaysRoot = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const DefaultOverlayContent = styled(motion.div)`
  height: ${(props) => props.vh};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position: sticky;
  top: 0;
  margin-top: -${(props) => props.vh};
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  padding: 0 20px;
  min-height: 80px;
  @media (min-width: 600px) {
    min-height: 100px;
    padding: 20px;
  }
`;

export const CustomerLogo = styled.img`
  width: 130px;
  @media (min-width: 600px) {
    width: 190px;
  }
`;

export const Heading = styled.div`
  margin-top: 16.5vh;

  width: 100%;
  text-align: center;

  > h1 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #fff;
  }
  > h2 {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #cecece;
    padding: 0 20px;
  }

  @media (min-width: 600px) {
    > h1 {
      font-size: 45px;
      line-height: 50px;
    }
    > h2 {
      font-size: 14px;
      line-height: 20px;
    }
  }

  /* &.coca_cola_na_sua_casa {
    > h1 {
      color: #393c41;
    }
    > h2 {
      color: #5c5e62;
    }
  } */
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 30px;
  /* z-index: 999; */

  button {
    background: #1a1720;
    color: #fff;
    opacity: 0.95;
    width: 90%;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4px;
    text-transform: uppercase;

    padding: 13px 40px;
    border-radius: 2px;
    border: none;
    outline: 0;
    cursor: pointer;
    z-index: 9999;

    &.white {
      background: #fff;
      color: #1a1720;
      opacity: 0.95;
    }

    & + button {
      margin: 10px 0 0;
    }
    @media (min-width: 600px) {
      width: inherit;
    }
  }

  @media (min-width: 600px) {
    flex-direction: row;
    margin-bottom: 30px;

    > button + button {
      margin: 0 0 0 10px;
    }
  }
`;

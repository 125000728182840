import React from "react";
import axios from "axios";
import swal from "sweetalert";

import { Container, Info, Form } from "./styles";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      name: "",
      email: "",
      tel: "",
      subject: props.subject || "",
      text: "",
    };
  }

  send() {
    if (this.state.name === "" || this.state.name.length < 3) {
      swal({
        title: "Nome é campo obrigatório.",
        text: "Favor corrigir e tentar novamente.",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    if (this.state.email === "" && this.state.tel === "") {
      swal({
        title: "Informe o e-mail ou telefone para podermos entrar em contato.",
        text: "Favor corrigir e tentar novamente.",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    if (this.validateEmail(this.state.email) === false && this.state.email !== "") {
      swal({
        title: "E-mail inválido.",
        text: "Favor corrigir e tentar novamente.",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    if (this.state.tel.length < 14 && this.state.tel !== "") {
      swal({
        title: "Telefone inválido.",
        text: "Favor corrigir e tentar novamente.",
        icon: "error",
        button: "OK",
      });
      return false;
    }
    this.setState({
      sending: true,
    });
    axios
      .post("https://rhodiumt.com.br/mail/send.php", this.state)
      .then((resp) => {
        swal({
          title: "Obrigado pelo contato!",
          text: "Em breve retornaremos.",
          icon: "success",
          button: "OK",
        });
        this.setState({
          sending: false,
          name: "",
          email: "",
          tel: "",
          subject: "",
          text: "",
        });
      })
      .catch((err) => {
        swal({
          title: "Desculpe-nos. :(",
          text: " Falha ao enviar a mensagem. Por favor tentar novamente mais tarde.",
          icon: "error",
          button: "OK",
        });
        this.setState({
          sending: false,
        });
      });
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  componentDidUpdate() {
    if (this.props.subject !== this.state.subject) {
      this.setState({
        subject: this.props.subject || "",
      });
    }
  }

  render() {
    // this.verifySubject();
    return (
      <Container>
        <Info>
          <h1>Converse com a gente</h1>
          <p>rhodion@rhodiumt.com.br</p>
        </Info>
        <Form>
          <h2>Se preferir, podemos retornar!</h2>
          <input
            value={this.state.name}
            onChange={(event) => this.setState({ name: event.target.value })}
            type="text"
            placeholder="Nome*"
          />
          <input
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value })}
            type="text"
            placeholder="E-mail"
          />
          <input
            onChange={(event) => {
              let tel = event.target.value.replace(/\D/g, "");
              const isCelular = tel.length === 11;

              let parte0;
              let parte1;
              let parte2;

              if (isCelular) {
                parte0 = tel.slice(0, 2);
                parte1 = tel.slice(2, 7);
                parte2 = tel.slice(7, 11);
              } else {
                parte0 = tel.slice(0, 2);
                parte1 = tel.slice(2, 6);
                parte2 = tel.slice(6, 10);
              }
              tel = `${parte0 !== "" ? "(" : ""}${parte0}${parte1 !== "" ? ") " : ""}${parte1}${
                parte2 !== "" ? "-" : ""
              }${parte2}`;

              this.setState({ tel });
            }}
            value={this.state.tel}
            type="text"
            placeholder="Telefone"
          />
          <input
            value={this.state.subject}
            onChange={(event) => this.setState({ subject: event.target.value })}
            type="text"
            placeholder="Assunto"
          />
          <textarea
            value={this.state.text}
            onChange={(event) => this.setState({ text: event.target.value })}
          >
            {this.state.text}
          </textarea>
          {this.state.sending ? (
            <button disabled onClick={() => {}}>
              Enviando
            </button>
          ) : (
            <button onClick={this.send.bind(this)}>Enviar</button>
          )}
        </Form>
      </Container>
    );
  }
}

export default Contact;
